import revive_payload_client_5reNi8Ej61 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_sass@1.77.8_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_2tyhPLqhzQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_sass@1.77.8_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Ina3fntOfE from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_sass@1.77.8_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_BVAv8jT4VD from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.15_vite@5.3.5_vue@3.4.36/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_LA9OvjTrjQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_sass@1.77.8_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_5TFXLGeoMP from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_sass@1.77.8_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_o81XU9R5Lv from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_sass@1.77.8_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_U6gVmya8jv from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_sass@1.77.8_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_Y1WSO8meVG from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_sass@1.77.8_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_5OGoPfE5jR from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import maska_ZPLJ7pnO0O from "/opt/buildhome/repo/packages/localdeck-config/packages/localdeck-components/src/plugins/maska.ts";
import matomo_client_1hOWyQFi23 from "/opt/buildhome/repo/src/plugins/matomo.client.ts";
import sentry_client_KAXFuL2wum from "/opt/buildhome/repo/src/plugins/sentry.client.ts";
import toast_client_5GxzM6P4QL from "/opt/buildhome/repo/src/plugins/toast.client.ts";
export default [
  revive_payload_client_5reNi8Ej61,
  unhead_2tyhPLqhzQ,
  router_Ina3fntOfE,
  _0_siteConfig_BVAv8jT4VD,
  payload_client_LA9OvjTrjQ,
  navigation_repaint_client_5TFXLGeoMP,
  check_outdated_build_client_o81XU9R5Lv,
  chunk_reload_client_U6gVmya8jv,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Y1WSO8meVG,
  plugin_client_5OGoPfE5jR,
  maska_ZPLJ7pnO0O,
  matomo_client_1hOWyQFi23,
  sentry_client_KAXFuL2wum,
  toast_client_5GxzM6P4QL
]